import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import About from "./about";
import { StaticQuery, graphql } from "gatsby";
import { rhythm } from "../utils/typography";
import { navLinks, navLinkItem, navLinkText, logoImage } from "./layout.module.css";

class Layout extends React.Component {
    render() {
        const { title, children } = this.props;
        let header;
        let footer;
        
        header = (
            <div style={{ display: `flex` }}>
                <StaticQuery
                    query={query}
                    render={(data) => {
                        const listItems = data.site.siteMetadata.menuLinks.map((linkItem) =>
                        <li className={navLinkItem} key={linkItem.name.toString()}>
                            <Link to={linkItem.link} className={navLinkText}>{linkItem.name}</Link>
                        </li>
                    );
                    
                    return (
                        <span>
                            <h2 style={{ marginTop: 10 }}>
                                <Image
                                    fixed={data.small.childImageSharp.fixed}
                                    className={logoImage}
                                />
                                <span>
                                    <Link
                                        style={{ boxShadow: `none`, textDecoration: `none`, color: `inherit`, }}
                                        to={`/`}
                                    >
                                        {title}
                                    </Link>
                                </span>
                            </h2>
                            <ul className={navLinks}>{listItems}</ul>
                        </span>
                    );
                }}
                />
            </div>
        );

        footer = (
            <div>
                <hr style={{ marginBottom: rhythm(1), }} />
                <About />
            </div>
        );

        return (
        <div
            style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: "1024px",
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
        >
            <header>{header}</header>
            <main>{children}</main>
            <footer>{footer}</footer>
        </div>
        );
    }
}

export const query = graphql`
  query {
    big: file(absolutePath: { regex: "/isologo.png/" }) {
        childImageSharp {
            fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
            }
        }
    }
    small: file(absolutePath: { regex: "/isologo.png/" }) {
        childImageSharp {
            fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
            }
        }
    }
    site {
        siteMetadata {
            menuLinks {
                name
                link
            }
        }
    }
  }
`;

export default Layout;
