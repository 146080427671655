import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { rhythm } from "../utils/typography";

function About() {
  return (
    <StaticQuery
      query={aboutQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata;
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            <p>
              Written with{" "}
              <span role="img" aria-label="love">
                ❤️
              </span>
              by <strong>{author}</strong> for YouBIM.
              {` `}|{` `}
              <a href={social.twitter}>Follow us!</a>
            </p>
          </div>
        );
      }}
    />
  );
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/isologo.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default About;
