import Typography from "typography";
import IrvingTheme from "typography-theme-irving";

IrvingTheme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    blockquote: {
      borderLeft: `none`,
    },
  };
};

//delete Wordpress2016.googleFonts;

const typography = new Typography(IrvingTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
