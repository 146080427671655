import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  youbimTitle,
  description,
  version,
  topSpacing,
} from "./index.module.css";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `blog`,
            `youbim`,
            `yif`,
            `documentation`,
            `javascript`,
            `react`,
          ]}
        />
        <h1>
          <div>
            <span className={youbimTitle}>YouBIM</span> Integration Framework
            <span className={version}>&nbsp;&nbsp;&nbsp;v1.43.0</span>
          </div>
        </h1>

        <h3 className={description}>
          The next generation 3D model visualization for BIM4FM.
        </h3>

        <div className={topSpacing} />

        <pre class="language-javascript line-numbers">
          <code class="language-javascript">
            &lt;script&gt;
            <br />
            <span class="token comment">
              &nbsp;&nbsp;&nbsp;&nbsp;// Container to append the integration
              framework to
            </span>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;<span class="token keyword">
              const
            </span>{" "}
            iFrameworkContainer = document.
            <span class="token function">querySelector</span>
            ('#youbim-integration-framework-container');
            <br />
            <span class="token comment">
              &nbsp;&nbsp;&nbsp;&nbsp;// Create the framework instance
            </span>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;<span class="token keyword">
              const
            </span>{" "}
            YouBIMIntegrationFrameworkInstance = YouBIMIntegrationFramework.
            <span class="token function">default</span>(<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iFrameworkContainer,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;horus,
            aphrodite, dataProvider, layers: []
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;);
            <br />
            &lt;/script&gt;
          </code>
        </pre>

        <div className={topSpacing}>
          <Image fluid={data.yifImage.childImageSharp.fluid} />
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    yifImage: file(absolutePath: { regex: "/yif.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
